<template>
  <div class="wrap">
    <div class="wrap-header">
      <el-breadcrumb>
        <el-breadcrumb-item class="font-breadcrumb"><a>Pre-declaration </a></el-breadcrumb-item>
        <el-breadcrumb-item> </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="wrap-header-btn">
        <el-button type="primary" plain size="small" class="public-fonts" @click="check()">Check Submit Status</el-button>
        <el-button type="primary" plain size="small" class="public-fonts" @click="create()">Create</el-button>
        <el-button type="primary" plain size="small" class="public-button-color public-fonts" @click="query()">Query</el-button>
      </div>
    </div>
    <div class="wrap-content">
      <el-table
        :data="loadData"
        @row-dblclick="viewDeclaration"
        class="content-table">
        <el-table-column
          prop="cargoType"
          label="Cargo Type"
          label-class-name="title">
          <template slot-scope="scope">
            <span>{{ judgeCargoType(scope.row.cargoType) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="uld"
          label="ULD#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="mawb"
          label="MAWB#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="dest"
          label="Dest."
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="flight"
          label="Flight#"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="flightDate"
          label="Flight Date"
          label-class-name="title">
        </el-table-column>
         <el-table-column
          prop="createdBy"
          label="Created By"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="submitTime"
          label="Submit Time"
          label-class-name="title">
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          label-class-name="title">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 'success'">SUCCESS</span>
            <span v-if="scope.row.status === 'fail'">FAIL</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="rclAvailable"
          label="RCL Available?"
          label-class-name="title">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, prev, slot, next, sizes, jumper, ->"
          :total="total">
          <span class="on-pager">{{ currentPage }}</span>
      </el-pagination>
    </div>
    <el-drawer
      :title="flagQuery !== null ? 'Input AWB#':'Query Criteria'"
      :visible.sync="querydrawer"
      :show-close="false"
      :direction="direction"
      :size="332">
      <Query @query-declaration-drawer="changeQuerydrawer"></Query>
    </el-drawer>
  </div>
</template>

<script>
import Query from '../declaration/query.vue'
import { queryPreDeclaration } from '@/api/declaration/declaration-api.js'
import { getOfficeCode } from '@/utils/auth'

export default {
  name: 'declaration-list',
  data () {
    return {
      loadData: [],
      currentPage: 1, // 当前页
      pageSize: 10,
      total: 0,
      querydrawer: false, // 查询抽屉
      direction: 'rtl', // 查询抽屉弹出的方向
      flagQuery: null,
      title: null
    }
  },
  components: {
    Query
  },
  computed: {
    judgeCargoType () {
      return val => {
        if (val === 'BUK') return 'BULK SHIPMENT'
        if (val === 'MIX') return 'MIX-LOAD SHIPMENT'
        if (val === 'PPK') return 'PRE-PACK SHIPMENT'
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const obj = {
        data: {},
        office: getOfficeCode(),
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      queryPreDeclaration(obj).then(res => {
        if (res.data.CODE === 200) {
          this.loadData = []
          const result = res.data.DATA
          result.records.forEach(element => {
            if (element.predecData) {
              this.loadData.push({
                preDeclarationId: element.preDeclarationId,
                cargoType: element.predecData.cargoType,
                uld: element.unitLoadDevice,
                mawb: element.mawbNo,
                dest: element.predecData.destination,
                flight: element.predecData.flightNo,
                flightDate: element.predecData.flightDate,
                createdBy: element.predecData.createdBy,
                submitTime: element.submitDtLoc,
                status: element.result,
                rclAvailable: element.rclAvailable
              })
            }
          })
          this.currentPage = result.pageNum
          this.pageSize = result.pageSize
          this.total = result.total
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    sizeChange (val) {
      this.pageSize = val
      const obj = {
        data: {},
        office: getOfficeCode(),
        pageNum: 1,
        pageSize: val
      }
      queryPreDeclaration(obj).then(res => {
        if (res.data.CODE === 200) {
          this.loadData = []
          const result = res.data.DATA
          result.records.forEach(element => {
            if (element.predecData) {
              this.loadData.push({
                preDeclarationId: element.preDeclarationId,
                cargoType: element.predecData.cargoType,
                uld: element.unitLoadDevice,
                mawb: element.mawbNo,
                dest: element.predecData.destination,
                flight: element.predecData.flightNo,
                flightDate: element.predecData.flightDate,
                createdBy: element.predecData.createdBy,
                submitTime: element.submitDtLoc,
                status: element.result,
                rclAvailable: element.rclAvailable
              })
            }
          })
          this.currentPage = result.pageNum
          this.pageSize = result.pageSize
          this.total = result.total
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    currentChange (val) {
      const obj = {
        data: {},
        office: getOfficeCode(),
        pageNum: val,
        pageSize: this.pageSize
      }
      queryPreDeclaration(obj).then(res => {
        if (res.data.CODE === 200) {
          this.loadData = []
          const result = res.data.DATA
          result.records.forEach(element => {
            if (element.predecData) {
              this.loadData.push({
                preDeclarationId: element.preDeclarationId,
                cargoType: element.predecData.cargoType,
                uld: element.unitLoadDevice,
                mawb: element.mawbNo,
                dest: element.predecData.destination,
                flight: element.predecData.flightNo,
                flightDate: element.predecData.flightDate,
                createdBy: element.predecData.createdBy,
                submitTime: element.submitDtLoc,
                status: element.result,
                rclAvailable: element.rclAvailable
              })
            }
          })
          this.currentPage = result.pageNum
          this.pageSize = result.pageSize
          this.total = result.total
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    query () {
      this.querydrawer = true
    },
    changeQuerydrawer (data) {
      this.querydrawer = data.close
    },
    check () {
      this.$router.push({
        path: '/declaration/check'
      })
    },
    create () {
      this.$router.push({
        path: '/declaration/create',
        query: {
          type: 'Create'
        }
      })
    },
    viewDeclaration (row) {
      this.$router.push({
        path: '/declaration/create',
        query: {
          type: 'View',
          preDeclarationId: row.preDeclarationId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$bg1:#1890ff;
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-content{
    padding: 5px 24px 24px;
    min-height: 72vh;
    .content-table{
      width: 100%;
      border-radius: 8px;
    }
    ::v-deep {
       .title{
        color: #000;
        font-size: 16px;
      }
    }
  }
}
</style>
